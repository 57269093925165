import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MailerService } from 'src/app/mailer.service';

@Component({
  selector: 'app-audimax-form',
  templateUrl: './audimax-form.component.html',
  styleUrls: ['./audimax-form.component.css']
})
export class AudimaxFormComponent implements OnInit {
  audimaxRequest = this.formBuilder.group({
    type: 'audimax',
    inputSurname: ['', Validators.required],
    inputLastname: ['', Validators.required],
    inputCompany: ['', Validators.required],
    inputStreet: ['', Validators.required],
    inputPhone: ['', Validators.required],
    inputEmail: ['', Validators.required],
    inputDate: [''],
    inputStart: [''],
    inputPause: [''],
    inputEnd: [''],
    inputGuests: [''],
    inputCheckServiceYes: [''],
    inputCheckServiceNo: [''],
    inputFingerfood: [''],
    inputBuffet: [''],
    inputSnacks: [''],
    inputDrinks: ['']
  });

  constructor(
    public mailerService: MailerService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.mailerService.sendEmail(this.audimaxRequest.value);
  }
}
