import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  NgbButtonsModule,
  NgbCarouselModule,
  NgbModalModule
} from '@ng-bootstrap/ng-bootstrap';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { DatenschutzComponent } from './components/modals/datenschutz/datenschutz.component';
import { BookingComponent } from './components/modals/booking/booking.component';
import { VoucherComponent } from './components/modals/voucher/voucher.component';
import { AudimaxFormComponent } from './components/modals/audimax-form/audimax-form.component';
import { CateringFormComponent } from './components/modals/catering-form/catering-form.component';
import { SectionComponent } from './components/section/section.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ApplyFormComponent } from './components/modals/apply-form/apply-form.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    ImpressumComponent,
    DatenschutzComponent,
    BookingComponent,
    VoucherComponent,
    AudimaxFormComponent,
    CateringFormComponent,
    SectionComponent,
    NavigationComponent,
    ApplyFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbButtonsModule,
    NgbCarouselModule,
    NgbModalModule,
    SlideshowModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DatenschutzComponent,
    BookingComponent,
    VoucherComponent,
    AudimaxFormComponent,
    CateringFormComponent,
    ApplyFormComponent
  ]
})
export class AppModule {}
