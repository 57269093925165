import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailerService {
  private API_PATH = 'https://unikat-regensburg.de/mailer.php';
  constructor(private httpClient: HttpClient) {}

  public sendEmail(data: any) {
    console.log(data);

    this.httpClient.post(this.API_PATH, data).subscribe(
      res => {
        console.log('res: ', res);
      },
      err => {
        console.log('err: ', err);
      }
    );
  }
}
