import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MailerService } from 'src/app/mailer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatenschutzComponent } from '../datenschutz/datenschutz.component';

@Component({
  selector: 'app-apply-form',
  templateUrl: './apply-form.component.html',
  styleUrls: ['./apply-form.component.css']
})
export class ApplyFormComponent implements OnInit {
  applyRequest = this.formBuilder.group({
    type: 'apply',
    inputSurname: ['', Validators.required],
    inputLastname: ['', Validators.required],
    inputStreet: ['', Validators.required],
    inputPhone: ['', Validators.required],
    inputPlace: ['', Validators.required],
    inputEmail: ['', Validators.required],
    inputNationality: [''],
    inputImage: [''],
    inputDate: [''],
    checkServiceManagment: [''],
    checkServiceWorker: [''],
    checkServicehelper: [''],
    checkChef: [''],
    checkBarista: [''],
    checkPizza: [''],
    checkKitchenHelper: [''],
    checkDishWasher: [''],
    checkFulltime: [''],
    checkParttime: [''],
    checkMini: [''],
    checkNoExperience: [''],
    checkExperience: [''],
    inputYears: [''],
    checkRestaurant: [''],
    checkCafe: [''],
    checkBar: [''],
    inputWhereWorked: [''],
    checkUnikat: [''],
    checkPapageno: ['']
  });

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public mailerService: MailerService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  open() {
    const modalRef = this.modalService.open(DatenschutzComponent, {
      size: 'lg'
    });
  }

  onSubmit() {
    this.mailerService.sendEmail(this.applyRequest.value);
  }
}
