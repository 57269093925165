import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MailerService } from 'src/app/mailer.service';

@Component({
  selector: 'app-catering-form',
  templateUrl: './catering-form.component.html',
  styleUrls: ['./catering-form.component.css']
})
export class CateringFormComponent implements OnInit {
  cateringRequest = this.formBuilder.group({
    type: 'catering',
    inputSurname: ['', Validators.required],
    inputLastname: ['', Validators.required],
    inputCompany: ['', Validators.required],
    inputStreet: ['', Validators.required],
    inputPhone: ['', Validators.required],
    inputEmail: ['', Validators.required],
    inputDate: [''],
    inputStart: [''],
    inputPause: [''],
    inputEnd: [''],
    inputGuests: [''],
    inputOccasion: [''],
    inputEURperson: [''],
    inputEURcatering: [''],
    inputInterest: ['']
  });

  constructor(
    public mailerService: MailerService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.mailerService.sendEmail(this.cateringRequest.value);
  }
}
