import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  private fragment: string;
  @Input() menuEntries: { name: string; anchor: string }[] = [];
  @ViewChild('mySidenav', { static: true }) public mySidenav: ElementRef;
  prevScrollpos = window.pageYOffset;

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {}

  ngOnInit() {}
  onAnchorLinkClicked(tag: string) {

    this.closeNav();
    this.fragment = tag;
    this.scrollToAnchor();
  }
  private scrollToAnchor() {
    console.log(this.fragment);
    setTimeout(() => {
      try {
        document
          .querySelector('#' + this.fragment)
          .scrollIntoView({ behavior: 'smooth' });
      } catch (e) {}
    });
  }

  openNav() {
    this.renderer.setStyle(this.mySidenav.nativeElement, 'width', '100%');
  }

  closeNav() {
    this.renderer.setStyle(this.mySidenav.nativeElement, 'width', '0');
  }
}
