import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  HostListener
} from '@angular/core';
import SimpleParallax from 'simple-parallax-js';
import { SectionContent } from '../models/section-content.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SectionButton } from '../models/section-botton.model';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent {
  @Input() content: SectionContent;
  @ViewChild('bgImage', { static: false }) bgImage: ElementRef;


  constructor(private modalService: NgbModal) { }

  onButtonClicked(button: SectionButton) {
    if (!!button.action) {
      if (!!button.action.openLink) {
        window.open(button.action.openLink.url, '_blank');
      }
      if (!!button.action.openComponent) {
        this.modalService.open(
          button.action.openComponent.componentType,
          button.action.openComponent.parameter
        );
      }
      if (!!button.action.anchor) {
        this.scrollToAnchor(button.action.anchor.tag);
      }

    }
  }

  initParallax(element: HTMLImageElement) {
    const parallax = new SimpleParallax(element, {
      scale: 1.7
    });
  }

  private scrollToAnchor(tag: string) {
    console.log(tag);
    setTimeout(() => {
      try {
        document
          .querySelector('#' + tag)
          .scrollIntoView({ behavior: 'smooth' });
      } catch (e) { }
    });
  }

  isArray(value: any) {
    return Array.isArray(value);
  }
}
