import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-datenschutz",
  templateUrl: "./datenschutz.component.html",
  styleUrls: ["./datenschutz.component.css"]
})
export class DatenschutzComponent implements OnInit {
  ngOnInit() {}

  constructor(public activeModal: NgbActiveModal) {}
}
