import { AfterViewInit, Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.css"]
})
export class BookingComponent implements AfterViewInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngAfterViewInit(): void {
    this.loadResmioWidget();
  }

  loadResmioWidget(): void {
    const script = document.createElement('script');
    script.src = "//static.resmio.com/static/de/widget.js#id=das-unikat-gmbhcokg&width=320px&height=500px&backgroundColor=282828&color=ffffff";
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  }
}
