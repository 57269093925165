import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatenschutzComponent } from "../modals/datenschutz/datenschutz.component";

@Component({
  selector: "app-impressum",
  templateUrl: "./impressum.component.html",
  styleUrls: ["./impressum.component.css"]
})
export class ImpressumComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  open() {
    const modalRef = this.modalService.open(DatenschutzComponent, { size: 'lg' });
  }
}
