import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SectionContent } from './components/models/section-content.model';
import { VoucherComponent } from './components/modals/voucher/voucher.component';
import { CateringFormComponent } from './components/modals/catering-form/catering-form.component';
import { AudimaxFormComponent } from './components/modals/audimax-form/audimax-form.component';
import { BookingComponent } from './components/modals/booking/booking.component';
import { ApplyFormComponent } from './components/modals/apply-form/apply-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'homepage';
  sectionContents: SectionContent[] = [
    {
      name: 'ToGo Order',
      id: 'onlineOrder',
      logo: 'assets/unikat-logo.png',
      buttons: [
        {
          name: 'ToGo Order',
          action: {
            openLink: {
              url: 'https://shop.unikat.unikat-regensburg.de/menu',
            },
          },
        },

      ],
      heroButtons: [
        {
          name: 'Loyalty',
          action: {
            anchor: {
              tag: 'loyalty',
            },
          },
        },
        {
          name: 'ToGo Order',
          action: {
            openLink: {
              url: 'https://shop.unikat.unikat-regensburg.de/menu',
            },
          },
        },
        {
          name: 'Reservieren',
          action: {
            openComponent: {
              componentType: BookingComponent,
              parameter: {},
            },
          },
        },
        {
          name: 'Gutschein',
          action: {
            anchor: {
              tag: 'voucher',
            },
          },
        },
      ],
      headline: 'Bestellung',
      image: 'assets/wand.jpg',
      text: `Bestellen und zahlen Sie bequem online Ihre Gerichte zum Abholen. Sie können sofort, aber auch erst für später oder den nächsten Tag vorbestellen. Sie müssen nur noch mit Ihrer Abholnummer, die sie per SMS erhalten, Ihr Essen im Unikat abholen.  Dafür ist keine Registrierung oder Angabe von persönlichen Daten nötig!`,
    },
    {
      name: 'Loyalty',
      title: 'Unikat Loyalty',
      id: 'loyalty',
      buttons: [
        {
          name: 'Anmeldung',
          action: {
            openLink: {
              url: 'https://forms.piggy.eu/forms/75f6de98-bbae-4af2-a626-a02ae6bca1aa/public/custom',
            }
          },
        },
        {
          name: 'Status Ebenen',
          action: {
            anchor: {
              tag: 'loyalty-bonus',
            }
          },
        },
        {
          name: 'Digitale Stempelkarte',
          action: {
            anchor: {
              tag: 'loyalty-card',
            }
          },
        },
      ],
      headline: 'MEMBERS LOYALTY',
      image: 'assets/herz.jpg',
      text: ['', 'Jetzt Punkte sammeln & köstliche Belohnungen erhalten!',
        'Tritt unserem "Unikat Loyalty" bei, sammle Punkte und genieße exklusive Belohnungen (digitale Stempelkarte) und Rabatte (Status-Ebenen).',
        '',
        'Erhalte regelmäßige Loyalty Insider Mailings mit weiteren leckeren Angeboten.',
        '',
        'Egal wo du am Campus bist, wir sind mit dem Restaurant DAS UNIKAT,',
        'der ALEA Kaffeebar (Audimax) und der CHINO Kaffeebar (Physik) in deiner Nähe ',
        '',
        ''
      ],
      subline: 'Du kannst dich jederzeit von unseren E-Mails abmelden!'

    },
    {
      title: 'Unikat Loyalty',
      id: 'loyalty-bonus',
      buttons: [],
      headline: 'BONUS LEVEL',
      image: 'assets/herz.jpg',
      titleTextPair: [
        {
          title: 'Level 1 | Ersti - Survivor', text:
            ['Willkommen, mit der Erstanmeldung schenken wir dir 150 Punkte!',
              'Pkt. 0000 - 1000*',
            ],
          icon: 'assets/icons/icon_transparent.png'
        },
        {
          title: 'Level 2 | Semester - Snacker', text:
            ['Um dir den Tag zu versüßen, erhältst du für das Erreichen des Semester-Snackers einmalig für dich und für einen deiner Freunde ein Croissant oder Kuchen deiner Wahl. Zudem bekommst du mit Level 2 <strong>immer 15%</strong> auf all unsere <strong>Snacks</strong> in der <strong>ALEA & CHINO</strong>.',
              'Pkt. 1001 - 2000*',
            ],
          icon: 'assets/icons/icon_transparent.png'
        },
        {
          title: 'Level 3 | Master of Munchies', text:
            [
              "Du bist der Knaller! Wir danken dir für deine Treue und freuen uns, dass es dich gibt. Hol dir dein besonderes Geschenk in der <strong>ALEA</strong> ab! Wir haben da etwas schönes für dich. Zudem bekommst du mit dem Level 3 im <strong>Unikat, ALEA & CHINO</strong> auf <strong>alles 10%!</strong>",
              "Pkt. 2001 - 9999*"
            ],
          icon: 'assets/icons/icon_transparent.png'

        },
      ],
    },




    {
      title: 'Unikat Loyalty',
      id: 'loyalty-card',
      buttons: [],
      headline: 'DIGITALE STEMPELKARTE',
      image: 'assets/herz.jpg',
      leadingTextPair: [
        {
          leading: '199 Pkt.', text:
            ['Espresso oder Espresso Macchiato'],
        },
        {
          leading: '250 Pkt.', text:
            ['Croissant (Naturale, Vanille, Schoko oder Pistazie)'],
        },
        {
          leading: '250 Pkt.', text:
            ['Deinen Lieblings-Cookie'],
        },
        {
          leading: '300 Pkt.', text:
            ['Kaffee- oder Chai-/spezialität'],
        },
        {
          leading: '450 Pkt.', text:
            ['Lecker Kuchen oder Muffin'],
        },
        {
          leading: '500 Pkt.', text:
            ['Aperitivo im Unikat'],
        },
      ],
    },
    {
      name: 'Reservierung',
      title: 'Reservierung',
      id: 'reservierung',
      buttons: [
        {
          name: 'Reservieren',
          action: {
            openComponent: {
              componentType: BookingComponent,
              parameter: {},
            },
          },
        },
      ],
      headline: 'Tisch jetzt schon sichern...',
      image: 'assets/reservierung.jpg',
      text: ` Reservieren sie ganz bequem online ihren Tisch. Sie erhalten umgehend eine Reservierungsbestätigung. Bei Reservierungen über 10 Personen,
      wenden sie sich bitte telefonisch oder persönlich an uns!`,
    },
    {
      name: 'Catering',
      title: 'Catering',
      id: 'catering',
      buttons: [
        {
          name: 'Catering anfragen ',
          action: {
            openComponent: {
              componentType: CateringFormComponent,
              parameter: { size: 'lg' },
            },
          },
        },
        {
          name: 'Cateringmappe',
          action: {
            openLink: {
              url: 'https://unikat-regensburg.de/catering_2020.pdf',
            },
          },
        },
        {
          name: 'Audimax anfragen ',
          action: {
            openComponent: {
              componentType: AudimaxFormComponent,
              parameter: { size: 'lg' },
            },
          },
        },
      ],

      image: 'assets/catering2.jpg',
      headline: 'Essen & Getränke für jeden Anlass.',
      text: `Sie suchen einen verlässlichen und professionellen Partner für Ihre Tagung, Veranstaltung oder Event?
      Wir übernehmen Caterings von 50 bis 800 Personen und stehen Ihnen von Anfang an beratend zur Seite..
      Sie haben eine Veranstaltung oder Konzert im Audimax?  Mit der Kaffee-/ Konzertbar ALEA und zwei mobilen Bars können wir Veranstaltungen bis 1450 Personen übernehmen. Auch hier stehen wir Ihnen gerne zur Seite. Unser Erfolg ist Ihre Zufriedenheit!`,
    },
    {
      name: 'Gutschein',
      title: 'Gutschein',
      id: 'voucher',
      buttons: [
        {
          name: 'Gutschein erwerben',
          action: {
            openLink: {
              url:
                'https://www.yovite.com/Restaurant-Gutschein-R-67705208.html?REF=REST',
            },
          },
        },
      ],
      headline: 'Auf der Suche nach einem Geschenk?',
      image: 'assets/wein2.jpg',
      text: `Hier erhalten Sie eine Gutschein, den Sie online zahlen und sofort ausdrucken können. Gerne erstellen wir Ihnen aber auch im Restaurant einen Gutschein ganz nach ihren belieben.
      `,
    },
    {
      name: 'Speisekarte',
      title: 'Speisekarte',
      id: 'card',
      buttons: [
        {
          name: 'Speisekarte',
          action: {
            openLink: {
              url: 'https://unikat-regensburg.de/speisekarte_2020.pdf',
            },
          },
        },
      ],

      video:
        'https://www.facebook.com/Unikat.Regensburg/videos/1596724303784001/',
      headline: 'Stöbern Sie gerne schon Mal durch unsere Karte',
      text: ``,
    },
    {
      title: 'Galerie',
      id: 'galerie',
      buttons: [],

      galleryImages: [
        { url: 'assets/catering.jpg' },
        { url: 'assets/chips.jpg' },
        { url: 'assets/pizza.jpg' },
        { url: 'assets/pizza2.jpg' },
        { url: 'assets/rollen.jpg' },
        { url: 'assets/salat.jpg' },
        { url: 'assets/shrimps.jpg' },
        { url: 'assets/unikat-6.jpg' },
        { url: 'assets/alea-0.jpeg' },
        { url: 'assets/alea-1.jpeg' },
        { url: 'assets/unikat-8.jpg' },
        { url: 'assets/unikat-9.jpg' },
        { url: 'assets/unikat-10.jpg' },
      ],
      image: 'assets/unikat-5.jpg',
    },
    {
      name: 'Bewerbung',
      title: 'Bewerbung',
      id: 'apply',
      buttons: [
        {
          name: 'bei uns bewerben ',
          action: {
            openComponent: {
              componentType: ApplyFormComponent,
              parameter: { size: 'lg' },
            },
          },
        },
      ],
      headline: 'Werde Teil unseres Teams',
      image: 'assets/team.jpg',
      text: ` Bewirb dich noch heute für das Campus Restaurant DAS UNIKAT, unserer Kaffee-/Konzertbar ALEA oder unserem Altstadtrestaurant Papageno Mittelmeerküche. Wir freuen uns auf euch!
      `,
    },
    {
      name: 'Kontakt/Anfahrt',
      title: 'Anfahrt',
      id: 'direction',
      buttons: [],
      headline: '',
      map: true,
      text: ``,
    },
  ];


  getMenuEntries(): { name: string; anchor: string }[] {
    const entries = this.sectionContents
      .map((section) => ({
        name: section.name,
        anchor: section.id,
      }))
      .filter((entry) => entry.name && entry.anchor);
    entries.push({ name: 'Öffnungszeiten', anchor: 'openingHours' });

    return entries;
  }


}
